.root {
  margin: 80px 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.privacy {
  background-color: rgba(255, 255, 255, 0.406);
  padding: 40px 148px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 2px grey;
  border-radius: 8px;
  display: flex;
  line-height: 24px;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 600px) {
    padding: 30px 40px;
  }

  @media (max-width: 320px) {
    padding: 20px 10px;
  }
}

.single_title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  margin-bottom: 16px;
}

.single_text {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 32px;
}

.title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 16px;
}

.requisites {
  background-color: rgba(255, 255, 255, 0.406);
  padding: 40px 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 2px grey;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 800px) {
    padding: 12px 24px;
  }
}

.row {
  display: grid;
  grid-template-columns: 6fr 1fr 6fr;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    overflow: auto;
  }
}

.row_item {
  border-bottom: 1px solid rgb(137, 173, 188);
}

.testing {
  width: 100%;
  height: 200px;
  opacity: 1;
  // margin: 24px 0;
  margin-bottom: 128px;

  display: grid;
  grid-template-columns: 9fr 10fr;
  gap: 24px;

  div {
    &:first-child {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 40px;
      font-weight: 600;
      color: #463b3b;
      background-color: white;
      overflow: hidden;
      width: 100%;

      height: 100%;
    }

    &:last-child {
      background-image: url('../assets/2/4.jpg');
      background-attachment: fixed;
      background-size: cover;
      // background-repeat: no-repeat;
      border-radius: 15px;
      text-shadow: 2px 2px #181212;
    }
  }
}

.line {
  line-height: 3rem;
}
