.root {
  display: flex;
  flex-direction: column;
  // background-image: url("../../assets/back1.png");
  // background-position: 100%;
  // background-size: 20%;
  margin-bottom: 128px;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  gap: 24px;

  justify-content: center;
  align-content: center;
  align-items: stretch;

  @media (max-width: 520px) {
  }

  margin-bottom: 128px;
}
.image-div {
  width: 100%;
  overflow: hidden;
}
.image_all {
  width: 100%;
  height: 300px;
  background: url('../../assets/2/3.jpg');
  background-size: 90%;
}
