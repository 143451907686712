.blockContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 64px;
    .textContainer {
      align-items: center;
    }
  }
}

.blockContainer_footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 40px 0;
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
  }
}

.textContainer {
  // background-position: 100%;
  // background-size: 70%;
  // background-image: url('../../assets/back1.png');
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 380px;
  transition: all 0.3s;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    // @media (max-width: 500px) {
    //   object-fit: scale-down;
    // }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
  }
}

.miniLogo {
  width: 40px;
  height: 40px;

  border-radius: 50%;
  overflow: hidden;
}

.textBlock {
  width: 100%;
  max-width: 500px;
  border-radius: 5%;
  // height: 100%;
  @media (max-width: 800px) {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    padding: 0 32px;
  }
}

.imageBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
  max-width: 700px;
  overflow: hidden;
  border-radius: 15px;
  // background: linear-gradient(
  //   135deg,
  //   rgb(255, 206, 236) 0%,
  //   rgb(152, 150, 240) 100%
  // );

  img {
    object-fit: cover;
  }
  @media (max-width: 500px) {
    height: 300px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  color: rgba(54, 54, 54, 1);
  @media (max-width: 800px) {
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
  }
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  padding: 24px 0;
  color: rgba(99, 98, 119, 1);
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 20px;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #574c4c;
  box-shadow: -2px -10px 20px 0px rgb(209, 207, 207);
}

.single {
  max-width: 800px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  align-items: center;
  margin: 0 auto;
  color: rgb(62, 62, 75);
}

.green {
  background-color: rgb(153, 176, 147);
}

.purple {
  background-color: rgb(197, 168, 196);
}

.white {
  background-color: none;
}

.blue {
  div {
    text-align: center;

    font-size: 30px;
    font-weight: 600;
    line-height: 1.8em;
  }
}
