.root {
  width: 100%;
  height: 100px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  color: white;
  margin-bottom: 64px;

  position: relative;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 16px;
    padding: 32px 0;
  }

  &::after {
    content: '';
    position: absolute;
    text-align: center;
    bottom: 0;

    width: 100%;
    height: 1px;
    background-color: rgba(13, 87, 234, 0.1);
    box-shadow: 0 0 1px 1px rgba(46, 10, 226, 0.1);
    border-radius: 50%;
  }
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: default;
  background: repeat url('../../../assets/6.png');
  background-position: 60%;
  background-size: 110%;
  transition: all 0.4s;
  &:hover {
    background-size: 140%;
    border-radius: 5%;
    transition: all 0.4s;
  }
}
.logoBlock {
  display: flex;
  gap: 32px;
  justify-content: baseline;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
  }
}
.logoText {
  font-size: 32px;
  font-weight: 600;
  color: rgb(192, 74, 182);
  text-shadow: 1px 1px black;
}
.navMenu {
  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
  }
}

.main_title {
}
